.messageContainer {
  width: 50%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  padding-left: 10px;
  border-radius: 10px;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #6b381d;
  margin: auto;
  font-weight: 600;
  font-size: 1.5rem;
  color: #6c5454;
  box-shadow: 0px 0px 8px rgba(192, 51, 51, 0.52);
}

.messageSVG {
  width: 50px;
}

@media screen and (min-width: 0px) AND (max-width: 600px) {
  /* Extra Small Screen Sizes */
  .messageContainer {
    width: 90%;
    padding: 10px 0px 10px;
    font-size: 1rem;
  }
}
@media screen and (min-width: 600px) AND (max-width: 1200px) {
  /* Small Screen Sizes */
  .messageContainer {
    width: 90%;
    padding: 10px 0px 10px;
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 1200px) {
  /* Medium Screen Sizes */
}
