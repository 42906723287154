.overLay {
    position: absolute;
    left:0;
    top:0;
    min-height:100%;
    max-height:9999px;
    width:100%;
    background: rgba(0, 0, 0, 0.74);
    cursor: pointer;
    z-index:19;
    padding-bottom:200px;
}

.hideOverFlow {
    overflow:hidden;
}

.map {
    margin-top: 100px;
    margin-left:50%;
    transform: translateX(-50%);
    z-index:20;
    width:70%;
    height:100%;
}

.map iframe {
    width:98% !important;
    border-radius:20px;
    height:600px;
}

@media only screen and (max-width: 690px) {
    .map {
        height:98%;
        height:500px;
    }

    .map iframe {
        width:96%;
        margin:auto;
        height:500px;
    }
}