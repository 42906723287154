.container {
    width:1024px;
    margin:50px auto 50px auto;
    border-radius:10px;
    -webkit-box-shadow: 0px 11px 18px -4px rgb(71, 73, 73);
    -moz-box-shadow: 0px 11px 18px -4px rgba(134,156,153,1);
    box-shadow: 0px 11px 18px -4px rgb(177, 177, 177);
    box-sizing: border-box;
    color: rgb(22, 22, 22);
    border:1px solid #1b9e9e;
    background-color: #ffffff;
    overflow:hidden;
    padding:5px;
}

.icon {
  float:left;
  height:65px;
  line-height:65px;
  padding:0 10px 0 10px;
}

.hoursWrapper {
  float:left;
}

.hoursHeader {
  display: flex;
  flex-direction: column;
    font-size:1.3em;
    margin-left:10px;
    font-weight:600;
}

.group {
  display: flex;
  flex-direction: row;
  margin-top:5px;
}

.hours {
    font-size:1.3em;
}

.container i {
    color: rgb(167, 91, 91) !important;
    position: relative;
    top:4px;
}

@media only screen and (max-width: 1024px) {
  .container {
    width:96%;
    font-size:0.9em;
  }
}