footer {
    margin-top:100px;
    margin-left:50%;
    transform: translateX(-50%);
    width:100%;
    height:5.5rem;
    text-align: center;
}


@media only screen and (max-width: 1024px) {
    footer {
        font-size:0.7em;
    }
}