header {
    width:1024px;
    height:170px;
    border-bottom:1px solid #ccc;
    background:#FCFCF3;
    -webkit-box-shadow: 0px 11px 18px -4px rgb(71, 73, 73);
    -moz-box-shadow: 0px 11px 18px -4px rgba(134,156,153,1);
    box-shadow: 0px 11px 18px -4px rgb(177, 177, 177);
    overflow:hidden;
    border-bottom:1px solid #1b9e9e;
    margin:auto;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius:20px;
    box-sizing: border-box;
    display:flex;
    flex: 1 1 auto;
    flex-direction:row;
}

.logo {
    margin-left:1%;
    transition: all 500ms;
    width:50%;
    display:inline-block;
    position: relative;
}

.headerInfo {
    margin-top:2%;
    width:50%;
    display:inline-block;
    font-size:1.3em;
}

.logo img {
    width:70%;
    display:inline-block;
    object-fit: cover;
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);

}





@media only screen and (max-width: 1020px) {
    header {
        width:98%;
        height:140px;
        font-size:0.7em;
    }

    .logo img {
        margin-left:0;
        width:100%;
        user-select: none;
    }

    .logo {
        height:100%;
        height:140px;
        display:inline-block;
        overflow:hidden;
        position: relative;
        user-select: none;
    }

    .group {
        margin-left:100px;
    }

}

@media only screen and (min-width: 730px) {
    @media only screen and (max-width: 1007px) {

        .logo img {
            width:70%;
        }

    }

}