body, html {
  width:100%;
  height:100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width:100%;
  box-sizing: border-box;
  overflow-x:hidden;
  background: rgb(255,255,255);
  background: radial-gradient(circle, rgba(255,255,255,1) 18%, rgba(11,85,85,0.205217291994923) 75%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
